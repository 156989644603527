

























































import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from 'moment'

const statusMap = {
  notStart: {
    status: 'notStart',
    statusText: () => '未开始',
    desc: () => '暂时无法进行自我评价或者说明',
  },
  process: {
    status: 'process',
    statusText: (plan: any) => plan.finishProgress * 10 + '%',
    desc: () => '计划正在进行中，您可以随时记录进度',
  },
  ended: {
    status: 'ended',
    statusText: () => '已结束',
    desc: () => '计划已结束，快来自我评价吧',
  },
  incomplete: {
    status: 'incomplete',
    statusText: () => '已结束',
    desc: () => '计划指标未完成',
  },
  completed: {
    status: 'completed',
    statusText: () => '已完成',
    desc: (plan: any) => plan.evaluation,
  },
}

@Component
export default class PlanList extends Vue {
  @Prop({ type: Boolean, default: false })
  loading!: boolean
  @Prop({ type: Array, default: () => [] })
  planList!: any[]

  get displayPlanList() {
    return this.planList.map((plan) => {
      const { planDate, startTime, endTime, finishStatus } = plan
      const now = moment()
      const startDate = moment(planDate + ' ' + startTime)
      const endDate = moment(planDate + ' ' + endTime)

      let status = {}
      if (finishStatus === 2) {
        status = statusMap.incomplete
      } else if (finishStatus === 3) {
        status = statusMap.completed
      } else {
        if (now.isBefore(startDate)) {
          status = statusMap.notStart
        } else if (now.isBetween(startDate, endDate)) {
          status = statusMap.process
        } else if (now.isAfter(endDate)) {
          status = statusMap.ended
        }
      }
      // if (now.isBefore(startDate)) {
      //   status = statusMap.notStart
      // } else if (now.isBetween(startDate, endDate)) {
      //   status = statusMap.process
      // } else if (now.isAfter(endDate)) {
      //   status = statusMap.ended
      //   if (finishStatus === 2) {
      //     status = statusMap.incomplete
      //   } else if (finishStatus === 3) {
      //     status = statusMap.completed
      //   }
      // }
      return {
        displayStatus: status,
        ...plan,
      }
    })
  }

  get planStatInfo() {
    return this.displayPlanList.reduce(
      (total, plan) => {
        const {
          displayStatus: { status },
        } = plan
        if (status === 'notStart') {
          total.notStart++
        } else if (status === 'process') {
          total.process++
        } else if (status === 'completed') {
          total.completed++
        }
        return total
      },
      {
        notStart: 0,
        process: 0,
        completed: 0,
      },
    )
  }

  handlePreview(record: any) {
    this.$emit('preview', record)
  }
  handleEvaluate(record: any) {
    const {
      displayStatus: { status },
    } = record
    if (status === 'notStart') return
    if (status === 'process') {
      this.$emit('editProcess', record)
      return
    }
    this.$emit('evaluate', record)
  }
}
