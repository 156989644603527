


















































































































import {
  Component,
  Vue,
  Prop,
  ModelSync,
  Ref,
  Watch,
} from 'vue-property-decorator'
import moment from 'moment'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import { updateEvaluation } from '@/api/dailyPlan'

const defaultForm = {
  finishStatus: 2,
  unfinishedReason: '',
  evaluation: '',
  satisfaction: 1,
}
@Component({
  components: { DatePicker },
})
export default class EvaluateModal extends Vue {
  @ModelSync('value', 'input', { type: Boolean, default: false })
  modalVisible!: boolean

  @Prop({ type: Object, default: () => moment() }) currentDate!: any

  @Prop({ type: Object, default: () => ({}) }) planInfo!: any

  moment = moment

  private submitLoading: boolean = false
  private innerMode: string = 'add'

  get isPreview() {
    return this.planInfo.finishStatus !== 1
  }

  get currentDateStr() {
    const week = ['一', '二', '三', '四', '五', '六', '日']
    return (
      this.currentDate.format('YYYY年M月DD日  周') +
      week[this.currentDate.isoWeekday() - 1]
    )
  }

  @Watch('value')
  handleValueChange(nval: boolean) {
    if (nval) {
      this.formData = {
        ...this.planInfo,
        ...defaultForm,
        finishStatus:
          this.planInfo.finishStatus === 1 ? 2 : this.planInfo.finishStatus,
      }
      if (this.planInfo.finishStatus === 1) {
        this.formData = {
          ...this.planInfo,
          ...defaultForm,
        }
      } else {
        this.formData = {
          ...this.planInfo,
        }
        this.formRule.unfinishedReason[0].required = false
        this.formRule.evaluation[0].required = false
      }
    } else {
      this.formData = { ...defaultForm }
    }
  }

  formData: any = { ...defaultForm }
  formRule: any = {
    unfinishedReason: [
      { required: true, message: '请填写未完成原因', trigger: 'blur' },
      {
        min: 1,
        max: 500,
        message: '未完成原因应为1到500个字符',
        trigger: 'blur',
      },
    ],
    evaluation: [
      { required: false, message: '请填写评价或备注说明', trigger: 'blur' },
      {
        min: 1,
        max: 500,
        message: '评价或备注说明应为1到500个字符',
        trigger: 'blur',
      },
    ],
  }

  @Watch('formData.finishStatus')
  handelFinishStatusChange(val: number) {
    this.formRule.unfinishedReason[0].required = val === 2
    if (val !== 2) {
      this.formData.unfinishedReason = ''
    }
    if (!this.isPreview) {
      this.formData.satisfaction = val === 3 ? 4 : 1
    }
  }

  @Ref('formRef')
  formRef: any
  async handleOk() {
    try {
      await this.formRef.validate()
      this.submitLoading = true
      const { id, finishStatus, unfinishedReason, evaluation, satisfaction } =
        this.formData
      const [err] = await updateEvaluation({
        id,
        finishStatus,
        unfinishedReason,
        evaluation,
        satisfaction,
      })
      this.submitLoading = false
      if (!err) {
        this.$message.success(`评价成功`)
        this.$emit('refresh')
        this.handleClose()
      }
    } catch (e) {
      this.submitLoading = false
      console.error('onSubmit error', e)
    }
  }

  handleClose() {
    this.modalVisible = false
  }
  editPlanStatus() {
    this.planInfo.finishStatus = 1
  }
}
