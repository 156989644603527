































































































































































































































import {
  Component,
  Vue,
  Prop,
  ModelSync,
  Ref,
  Watch,
} from 'vue-property-decorator'
import moment from 'moment'
import { cloneDeep } from 'lodash-es'
import { uuid } from '@/utils/util'
import Commit from '@/components/comment/index.vue'
import Share from '@/components/Share/index.vue'
import ThumbsUp from '@/components/ThumbsUp/index.vue'
import DatePicker from 'vue2-datepicker'
import MyTinymce from '@/components/Editor/index.vue'
import 'vue2-datepicker/index.css'

import { addDailyPlan, editDailyPlan, dailyPlanDetail } from '@/api/dailyPlan'

const defaultForm = {
  time: [],
  workPlan: '',
  indicatorRecords: [
    {
      uid: Date.now(),
      indicatorType: undefined,
      indicatorDescription: '',
    },
  ],
  businessAttachmentList: [],
  shareList: [],
}
@Component({
  components: { DatePicker, Commit, ThumbsUp, Share, MyTinymce },
})
export default class PlanInfoModal extends Vue {
  @ModelSync('value', 'input', { type: Boolean, default: false })
  modalVisible!: boolean

  @Prop({ type: Object, default: () => moment() }) currentDate!: any

  @Prop({ type: String, default: 'add' }) mode!: string

  @Prop({ type: Object, default: () => ({}) }) planInfo!: any

  moment = moment

  private submitLoading: boolean = false
  private innerMode: string = 'add'
  private tinymceMounted: boolean = false
  private panelOpend: boolean = false

  get isPreview() {
    return this.innerMode === 'preview'
  }

  get title() {
    return this.isPreview ? '查看' : this.innerMode === 'edit' ? '编辑' : '新建'
  }

  get currentDateStr() {
    const week = ['一', '二', '三', '四', '五', '六', '日']
    return (
      this.currentDate.format('YYYY年M月DD日  周') +
      week[this.currentDate.isoWeekday() - 1]
    )
  }

  get previewWorkPlan() {
    return this.formData.workPlan.replace(/\n/g, '<br/>')
  }

  toogleEdit() {
    this.formData.indicatorRecords =
      this.formData.indicatorRecords.length < 1
        ? cloneDeep(defaultForm.indicatorRecords)
        : this.formData.indicatorRecords
    this.innerMode = 'edit'
  }

  @Watch('value')
  handleValueChange(nval: boolean) {
    this.innerMode = this.mode
    if (nval) {
      this.getDetailById()
      this.$nextTick(() => {
        this.tinymceMounted = true
      })
    } else {
      this.tinymceMounted = false
      this.formData = { ...cloneDeep(defaultForm) }
    }
  }

  formData: any = { ...cloneDeep(defaultForm) }
  formRule = {
    workPlan: [
      { required: true, message: '请填写工作计划内容', trigger: 'blur' },
      // {
      // min: 1,
      // max: 500,
      // message: '工作计划内容应为1到500个字符',
      // trigger: 'blur',
      // },
    ],
  }

  handleAddIndex() {
    this.formData.indicatorRecords.push({
      uid: uuid(),
      indicatorType: undefined,
      indicatorDescription: '',
    })
  }
  addShareList(e: any) {
    this.formData.shareList = e
  }
  handleDeleteIndex(index: number) {
    this.formData.indicatorRecords.splice(index, 1)
  }

  private oldFormData = {}
  async getDetailById() {
    if (!this.planInfo.id) {
      this.formData = {
        ...cloneDeep(defaultForm),
        indicatorRecords: [
          {
            uid: Date.now(),
            indicatorType: undefined,
            indicatorDescription: '',
          },
        ],
        time: [
          moment().format('HH:mm'),
          moment().add(1, 'hours').format('HH:mm'),
        ],
      }
      const formData = cloneDeep(this.formData)
      formData.indicatorRecords = []
      this.oldFormData = formData
      return
    }
    const [err, res] = await dailyPlanDetail(this.planInfo.id)
    if (err) return
    const {
      startTime,
      endTime,
      indicatorRecords = [],
      businessAttachmentList = [],
    } = res
    this.formData = {
      ...res,
      time: [startTime, endTime],
      indicatorRecords: indicatorRecords.map((item: any) => ({
        ...item,
        uid: uuid(),
      })),
      businessAttachmentList,
    }
    this.oldFormData = { ...cloneDeep(this.formData) }
  }

  @Ref('formRef')
  formRef: any
  async handleOk() {
    try {
      await this.formRef.validate()
      const { time, indicatorRecords, businessAttachmentList, ...data } =
        this.formData
      if (
        indicatorRecords.filter(
          (item: any) =>
            (item.indicatorType && !item.indicatorDescription) ||
            (!item.indicatorType && item.indicatorDescription),
        ).length > 0
      ) {
        return this.$message.warn('请完善指标类型说明')
      }
      this.submitLoading = true
      const isEdit = !!this.formData.id
      const remoteMethod = isEdit ? editDailyPlan : addDailyPlan
      const planDate = data.planDate || this.currentDate.format('YYYY-MM-DD')
      const [err] = await remoteMethod({
        ...data,
        startTime: planDate + ' ' + time[0],
        endTime: planDate + ' ' + time[1],
        indicatorRecords: indicatorRecords.filter(
          (item: any) => item.indicatorType,
        ),
        planDate,
        businessAttachmentList: businessAttachmentList.map((item: any) => ({
          attachmentName: item.attachmentName,
          attachmentUrl: item.attachmentUrl,
        })),
      })
      this.submitLoading = false
      if (!err) {
        this.$message.success(`${isEdit ? '编辑' : '添加'}成功`)
        this.$emit('refresh', !isEdit)
        this.handleClose()
      }
    } catch (e) {
      this.submitLoading = false
      console.error('onSubmit error', e)
    }
  }

  isChange() {
    // const newFormData = { ...cloneDeep(this.formData) }
    // newFormData.indicatorRecords = newFormData.indicatorRecords.filter(
    //   (item: any) => item.indicatorType || item.indicatorDescription,
    // )
    // const newFormStr = JSON.stringify(newFormData)
    // const oldFormStr = JSON.stringify(this.oldFormData)
    // console.log(this.oldFormData, newFormData, oldFormStr === newFormStr)
    return false
  }

  handleClose() {
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onOk: () => {
          this.modalVisible = false
        },
      })
    } else {
      this.modalVisible = false
    }
  }
}
